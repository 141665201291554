import { P as PromoUtils } from "./promotion.planning.js";
import { n as normalizeComponent } from "./index.js";
import "@kraftheinz/common";
import "numeral";
var render$1 = function() {
  var _vm = this;
  var _h = _vm.$createElement;
  var _c = _vm._self._c || _h;
  return _c("create-modal-composer", { attrs: { "title": "Add Event" } }, [_c("select-input", { key: "customerId", attrs: { "default-active-first-option": "", "label": "Key Account", "option-filter": _vm.filterCustomers, "place-holder": "Key Account", "reference": "promotion-planning.common.customers", "source": "id", "source-label": function(val) {
    return _vm.generateLabel(val, ["region", "keyAccountCode", "keyAccount"]);
  }, "span": 24 } }), _c("text-input", { key: "name", attrs: { "label": "Event", "max-length": 512, "place-holder": "Event", "rules": "required|max:512", "span": 24 } })], 1);
};
var staticRenderFns$1 = [];
const __vue2_script$1 = {
  name: "CreateEvent",
  mixins: [PromoUtils],
  methods: {
    filterCustomers(customer) {
      return customer.id !== 0;
    }
  }
};
const __cssModules$1 = {};
var __component__$1 = /* @__PURE__ */ normalizeComponent(__vue2_script$1, render$1, staticRenderFns$1, false, __vue2_injectStyles$1, null, null, null);
function __vue2_injectStyles$1(context) {
  for (let o in __cssModules$1) {
    this[o] = __cssModules$1[o];
  }
}
var CreateEvent = /* @__PURE__ */ function() {
  return __component__$1.exports;
}();
var render = function() {
  var _vm = this;
  var _h = _vm.$createElement;
  var _c = _vm._self._c || _h;
  return _c("div", [_c("resource", { attrs: { "api-url": _vm.apiUrl, "name": "promotion-planning.common.customers" } }), _c("resource", { attrs: { "api-url": _vm.apiUrl, "name": "promotion-planning.events", "redirect-route": "/promotion-planning/event-list" } }, [_c("create-event")], 1)], 1);
};
var staticRenderFns = [];
const apiUrl = "#{VUE_APP_API_URL}#";
const __vue2_script = {
  components: { CreateEvent },
  data() {
    return {
      apiUrl
    };
  }
};
const __cssModules = {};
var __component__ = /* @__PURE__ */ normalizeComponent(__vue2_script, render, staticRenderFns, false, __vue2_injectStyles, null, null, null);
function __vue2_injectStyles(context) {
  for (let o in __cssModules) {
    this[o] = __cssModules[o];
  }
}
var index = /* @__PURE__ */ function() {
  return __component__.exports;
}();
export { index as default };
